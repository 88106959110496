<template>
  <ItemList
    @addItem="addPark"
    @removeItem="removePark"
    :items="parks"
    :isLoadin="isLoadingList"
    noDataText="Nenhum Estacionamento encontrado"
    label="Estacionamento"
  />
</template>

<script>
export default {
  components: {
    ItemList: () => import('./ItemList.vue'),
  },
  data: () => ({
    parks: [],
    isLoadingList: false,
  }),
  methods: {
    getParks() {
      this.isLoadingList = true
      this.$api
        .get('parkings')
        .then((res) => {
          this.parks = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.isLoadingList = false
        })
    },
    addPark(value) {
      this.$api
        .post('parkings/store', { name: value })
        .then((res) => {
          this.parks.push(res.data.data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    removePark(item) {
      this.$api
        .post('parking/delete', item)
        .then(() => {
          let idx = this.parks.indexOf(item)
          this.parks.splice(idx, 1)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  mounted() {
    if (this.parks.length === 0) {
      this.getParks()
    }
  },
}
</script>

<style>
</style>